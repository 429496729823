	/*
  	Flaticon icon font: Flaticon
  	Creation date: 16/05/2020 07:37
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-healthcare-and-medical:before { content: "\f100"; }
.flaticon-fitness:before { content: "\f101"; }
.flaticon-clock:before { content: "\f102"; }