.custom-otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.custom-otp-input {
  width: 6rem !important;
  height: 5rem;
  color: var(--primary-color);
  font-size: 20px;
  border: 2px solid var(--secondary-color);
  border-radius: 5px;
  text-align: center;
  margin: 0 5px;
}

.custom-separator {
  font-size: 20px;
  margin: 0 5px;
}


@media (max-width: 678px) {
  .custom-otp-input {
    width: 4rem !important;
    height: 4rem;
    color: var(--primary-color);
    font-size: 20px;
    border: 2px solid var(--secondary-color);
    border-radius: 5px;
    text-align: center;
    margin: 0 5px;
  }
}