.progressbar {
  margin: 50px 0 50px 0;
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 25%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: var(--secondary-color);
}
.progressbar li:before {
  width: 15px;
  height: 15px;
  content: '';
  line-height: 30px;
  border: 2px solid var(--secondary-color);
  background-color: var(--secondary-color);
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  transition: all .8s;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: var(--secondary-color);
  top: 7px;
  left: -50%;
  z-index: -1;
  transition: all .8s;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active:before {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  transition: all .8s;
}
.progressbar li.active:after {
  background-color: var(--primary-color);
  transition: all .8s;
}

.d-sch-table {
  position: relative;
  padding: 40px;
  border: solid 1px var(--primary-color);
}


.radio-item [type="radio"] {
  display: none;
}


.radio-item label {
  background: transparent;
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: 0.4s ease-in-out 0s;
}

.radio-item label:after,
.radio-item label:before {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.radio-item label:after {
  height: 20px;
  width: 20px;
  border: 2px solid var(--primary-color);
  left: 95%;
  top: calc(50% - 10px);
}

.radio-item label:before {
  background: #fff;
  height: 18px;
  width: 18px;
  left: 95%;
  top: 50%;
  top: calc(50% - 9px);
  transform: scale(5);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease-in-out 0s;
}

.radio-item [type="radio"]:checked~label {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.radio-item [type="radio"]:checked~label::before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  background-color: var(--secondary-color);
}

/* @media (max-width: 678px) {
  .radio-item label:after {
    left: 90%;
  }
  .radio-item label:before {
    left: 90%;
  }
} */