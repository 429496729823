@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Oswald:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600&display=swap");
@import url("../colors/scheme-01.css");

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

/* navbar section */
.navlink-color {
  color: var(--secondary-color);
}

.navbar-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

/* Waitlist height */
.mobile-height {
  height: 600px;
}

@media (max-width: 678px) {
  .mobile-height {
    height: 380px;
  }
}

/* date and time mobile height */
@media (max-width: 767px) {
  .mobile-view {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 260px;
  }
}


/* Trending section */
/* Customize the pagination color */
.swiper-pagination-bullet {
  background-color: var(--primary-color) !important;
  /* Change the color as desired */
}

/* Navbar */
.mobile-nav {
  color: #000 !important;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-nav-text {
  color: #000;
  font-size: 30px;
}

.mobile-nav-active {
  color: white !important;
}

/* NameAndNumber component */
.info-input {
  border: 1px solid var(--primary-color);
  border-radius: 3px;
}

/* Input radio button */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Header Line */
.header-line {
  border: 3px solid var(--primary-color);
  width: 70%;
  margin: auto;
  border-radius: 3px 3px 0 0;
}

.header-line2 {
  border: 3px solid var(--secondary-color);
  width: 70%;
  margin: auto;
  border-radius: 0 0 3px 3px;
}

.header-line-pricing {
  border: 1.5px solid var(--primary-color);
  border-radius: 3px 3px 3px 3px;
}

.header-line-pricing2 {
  border: 2px solid var(--secondary-color);
  border-radius: 0 0 3px 3px;
}

/* Galley style start*/

.gallery-background {
  background-color: var(--secondary-color);
}

/* Steps */
.steps-custom {
  background-color: var(--primary-color);
}

.dih-overlay {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--primary-color);
  opacity: 0;
  transition: 0.2s all ease-out;
}

.de-image-hover {
  position: relative;
  overflow: hidden;
}

.de-image-hover:hover .dih-overlay {
  opacity: 0.6;
  transition: 0.2s all ease-out;
}

.de-image-hover img {
  transform: scale(1);
  transition: 0.5s all ease-out;
}

.de-image-hover:hover img {
  transform: scale(1.1);
}

.de-image-hover:hover {
  -webkit-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
}

.gallery-img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 440px;
}

@media (max-width: 1280px) {
  .gallery-img {
    height: 330px;
  }
}

@media (max-width: 1024px) {
  .gallery-img {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .gallery-img {
    height: 300px;
  }
}

/* Gallery style end */

.inner-img:hover {
  transform: scale(1.1);
}

.hover-color {
  background-color: var(--primary-color);
}

/* button section */

.boxed-btn {
  background: var(--tertiary-color);
  color: var(--primary-color) !important;
  box-shadow: 0.5rem 0.5rem var(--primary-color),
    -0.5rem -0.5rem var(--secondary-color);
  display: inline-block;
  padding: 14px 44px;
  font-family: "Oswald", sans-serif;
  font-size: 14px;
  font-weight: 800;
  border: 0;
  letter-spacing: 3px;
  text-align: center;
  color: var(--primary-color);
  transition: 0.2s all ease;
  text-transform: uppercase;
  cursor: pointer;
}

.boxed-btn:hover {
  box-shadow: 0.5rem 0.5rem var(--secondary-color),
    -0.5rem -0.5rem var(--primary-color);
  color: var(--secondary-color) !important;
  transition: 0.2s all ease;
}

.boxed-btn:focus {
  outline: none;
}

.boxed-btn.large-width {
  width: 220px;
}

[data-overlay] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

[data-overlay]::before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  background-color: var(--background-primary-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
  color: var(--text-primary-color);
  margin-top: 0px;
  font-style: normal;
  font-weight: 500;
  text-transform: normal;
}

p, a, span, button, link, li{
  color: var(--text-primary-color);
}

.form-control{
  color: var(--text-primary-color);
}

.img {
  max-width: 100%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

a,
.button {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

input:focus::-moz-placeholder {
  opacity: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.capitalize {
  text-transform: capitalize;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

.btn {
  background: var(--primary-color);
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  padding: 27px 44px;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  border-radius: 0px;
  line-height: 1;
  -moz-user-select: none;
  letter-spacing: 1px;
  line-height: 0;
  margin-bottom: 0;
  margin: 10px;
  cursor: pointer;
  transition: color 0.4s linear;
  position: relative;
  z-index: 1;
  border: 0;
  overflow: hidden;
  margin: 0;
}

.btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 101%;
  height: 101%;
  background: var(--primary-color-rgb);
  z-index: 1;
  border-radius: 5px;
  transition: transform 0.5s;
  transition-timing-function: ease;
  transform-origin: 0 0;
  transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
  transform: scaleX(0);
  border-radius: 0px;
}

.btn:hover::before {
  transform: scaleX(1);
  color: #fff !important;
  z-index: -1;
}

.btn:hover {
  background-position: right;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

#scrollUp,
#back-top {
  background: var(--primary-color);
  height: 50px;
  width: 50px;
  right: 31px;
  bottom: 18px;
  z-index: 9999999;
  position: fixed;
  color: #fff;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  line-height: 48px;
  border: 2px solid transparent;
  box-shadow: 0 0 10px 3px rgba(108, 98, 98, 0.2);
}

@media (max-width: 575px) {
  #scrollUp,
  #back-top {
    right: 16px;
  }
}

#scrollUp a i,
#back-top a i {
  display: block;
  line-height: 50px;
}

#scrollUp:hover {
  color: #fff;
}

/* Custom Margin */

.mb-120 {
  margin-bottom: 120px;
}

.pb-180 {
  padding-bottom: 180px;
}
.pb-80 {
  padding-bottom: 80px;
}

.bounce-animate {
  animation-name: float-bob;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -moz-animation-name: float-bob;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes float-bob {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

.heartbeat {
  animation: heartbeat 1s infinite alternate;
}

@-webkit-keyframes heartbeat {
  to {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
}

.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.preloader {
  background-color: var(--tertiary-color);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  margin: 0 auto;
}

.preloader .preloader-circle {
  width: 100px;
  height: 100px;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-top-color: var(--primary-color);
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 10;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 5px 0 var(--secondary-color-rgb);
  box-shadow: 0 1px 5px 0 var(--secondary-color-rgb);
  background-color: var(--tertiary-color);
  -webkit-animation: zoom 2000ms infinite ease;
  animation: zoom 2000ms infinite ease;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.preloader .preloader-circle2 {
  border-top-color: #0078ff;
}

.preloader .preloader-img {
  position: absolute;
  top: 50%;
  z-index: 200;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 6px;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.preloader .preloader-img img {
  max-width: 55px;
}

.preloader .pere-text strong {
  font-weight: 800;
  color: var(--primary-color);
  text-transform: uppercase;
}

@-webkit-keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}

@keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}

.section-padding2 {
  padding-top: 200px;
  padding-bottom: 200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .section-padding2 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding2 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding2 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding2 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (max-width: 575px) {
  .section-padding2 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.footer-padding {
  padding-top: 150px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-padding {
    padding-top: 150px;
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-padding {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-padding {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}

@media (max-width: 575px) {
  .footer-padding {
    padding-top: 80px;
    padding-bottom: 0px;
  }
}

@media (max-width: 575px) {
  .section-tittle {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-tittle {
    margin-bottom: 50px;
  }
}

.section-tittle span {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 22px;
  color: var(--secondary-color);
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.05em;
}

@media (max-width: 575px) {
  .section-tittle span {
    font-size: 14px;
  }
}

.section-tittle h2 {
  font-size: 50px;
  display: block;
  color: var(--text-primary-color);
  font-weight: 700;
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-tittle h2 {
    font-size: 48px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-tittle h2 {
    font-size: 31px;
  }
}

@media (max-width: 575px) {
  .section-tittle h2 {
    font-size: 27px;
  }
}

.section-tittle2 h2 {
  color: var(--tertiary-color);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-tittle2 h2 {
    font-size: 39px;
  }
}

@media (max-width: 575px) {
  .section-tittle2 h2 {
    font-size: 25px;
  }
}

.section-tittle3 h2 {
  font-size: 44px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-tittle3 h2 {
    font-size: 38px;
  }
}

@media (max-width: 575px) {
  .section-tittle3 h2 {
    font-size: 26px;
  }
}

/* .section-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
} */

.header-area .main-header {
  padding: 0px 130px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header-area .main-header {
    padding: 0px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-area .main-header {
    padding: 0px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .main-header {
    padding: 22px 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-area .main-header {
    padding: 22px 20px;
  }
}

@media (max-width: 575px) {
  .header-area .main-header {
    padding: 22px 14px;
  }
}

.header-area .main-header .main-menu ul li {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.header-area .main-header .main-menu ul li a {
  color: var(--tertiary-color);
  font-weight: 300;
  padding: 39px 25px;
  display: block;
  font-size: 16px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-transform: capitalize;
  position: relative;
  font-family: "Open Sans", sans-serif;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-area .main-header .main-menu ul li a {
    padding: 39px 15px;
  }
}

.header-area .main-header .main-menu ul li a::before {
  position: absolute;
  content: "";
  width: 14px;
  height: 1px;
  background: var(--primary-color);
  left: 25px;
  bottom: 33px;
  transform: rotate(-46deg);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-area .main-header .main-menu ul li a::before {
    left: 17px;
  }
}

.header-area .main-header .main-menu ul li a::after {
  position: absolute;
  content: "";
  width: 14px;
  height: 1px;
  background: var(--primary-color);
  left: 57px;
  top: 37px;
  transform: rotate(-46deg);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-area .main-header .main-menu ul li a::after {
    left: 45px;
  }
}

.header-area .main-header .main-menu ul li.active > a {
  color: var(--primary-color);
}

.header-area .main-header .main-menu ul li.active > a::before {
  opacity: 1;
  visibility: visible;
}

.header-area .main-header .main-menu ul li.active > a::after {
  opacity: 1;
  visibility: visible;
}

.header-area .main-header .main-menu ul li:hover > a {
  color: var(--primary-color);
}

.header-area .main-header .main-menu ul li:hover > a::before {
  opacity: 1;
  visibility: visible;
}

.header-area .main-header .main-menu ul li:hover > a::after {
  opacity: 1;
  visibility: visible;
}

.header-area .main-header .main-menu ul ul.submenu {
  position: absolute;
  width: 170px;
  background: var(--secondary-color-rgb);
  left: 0;
  top: 80%;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  padding: 17px 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.header-area .main-header .main-menu ul ul.submenu > li {
  margin-left: 7px;
  display: block;
}

.header-area .main-header .main-menu ul ul.submenu > li > a {
  padding: 6px 10px !important;
  font-size: 16px;
  text-transform: capitalize;
  margin: 0;
}

.header-area .main-header .main-menu ul ul.submenu > li > a:hover {
  color: var(--primary-color);
  background: none;
}

.header-area .main-header .main-menu ul ul.submenu > li > a::before {
  position: unset;
}

.header-area .main-header .main-menu ul ul.submenu > li > a::after {
  position: unset;
}

.header-area .main-menu ul ul.submenu > li > a:hover {
  padding-left: 13px !important;
}

.header-area .main-menu ul > li:hover > ul.submenu {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.header-transparent {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
}

.header-sticky.sticky-bar {
  background: var(--secondary-color);
}

.header-sticky.sticky-bar.sticky .main-menu ul li a {
  padding: 20px 20px !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-sticky.sticky-bar.sticky {
    padding: 15px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-sticky.sticky-bar.sticky {
    padding: 15px 0px;
  }
}

@media (max-width: 575px) {
  .header-sticky.sticky-bar.sticky {
    padding: 15px 0px;
  }
}

.mobile_menu {
  position: absolute;
  right: 0px;
  width: 100%;
  z-index: 99;
}

.mobile_menu .slicknav_menu {
  background: transparent;
  margin-top: 0px !important;
}

.mobile_menu .slicknav_menu .slicknav_btn {
  top: -36px;
}

.mobile_menu .slicknav_menu .slicknav_btn .slicknav_icon-bar {
  background-color: var(--primary-color) !important;
}

.mobile_menu .slicknav_menu .slicknav_nav {
  margin-top: 16px !important;
}

.mobile_menu .slicknav_menu .slicknav_nav a:hover {
  background: transparent;
  color: var(--primary-color);
}

.mobile_menu .slicknav_menu .slicknav_nav a {
  font-size: 15px;
  padding: 7px 10px;
}

.mobile_menu .slicknav_menu .slicknav_nav .slicknav_item a {
  padding: 0 !important;
}

.slider-height {
  height: 1000px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height {
    height: 800px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height {
    height: 650px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height {
    height: 600px;
  }
}

@media (max-width: 575px) {
  .slider-height {
    height: 460px;
  }
}

.slider-height2 {
  height: 450px;
  background-size: cover;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height2 {
    height: 350px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height2 {
    height: 260px;
  }
}

@media (max-width: 575px) {
  .slider-height2 {
    height: 260px;
  }
}

.slider-area2 {
  background-image: url(../../../public/img/hero/hero2.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-area {
  background-image: url(../../../public/img/hero/h1_hero.webp);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;
}

.slider-area .hero__caption {
  overflow: hidden;
}

.slider-area .hero__caption span {
  overflow: hidden;
  font-size: 20px;
  color: var(--text-secondary-color);
  font-weight: 500;
  margin-bottom: 26px;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

.slider-area .hero__caption h1 {
  overflow: hidden;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 14px;
  color: var(--text-secondary-color);
  line-height: 1.4;
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-area .hero__caption h1 {
    font-size: 54px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .hero__caption h1 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-area .hero__caption h1 {
    font-size: 35px;
  }
}

@media (max-width: 575px) {
  .slider-area .hero__caption h1 {
    font-size: 29px;
    line-height: 1.5;
  }
}

.slider-area .hero__caption p {
  font-size: 18px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  margin-bottom: 50px;
  padding-right: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .hero__caption p {
    padding-right: 13px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-area .hero__caption p {
    padding-right: 0px;
  }
}

@media (max-width: 575px) {
  .slider-area .hero__caption p {
    padding-right: 0px;
    font-size: 15px;
  }
}

.slider-area .stock-text {
  position: relative;
  left: 22px;
  bottom: 345px;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-area .stock-text {
    bottom: 135px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .stock-text {
    bottom: 178px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-area .stock-text {
    bottom: 178px;
  }
}

@media (max-width: 575px) {
  .slider-area .stock-text {
    bottom: 135px;
  }
}

.slider-area .stock-text h2 {
  font-size: 122px;
  font-weight: 700;
  font-family: "Oswald", sans-serif;
  color: #fff;
  margin: 0;
  line-height: 1;
  text-transform: uppercase;
  position: absolute;
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
  .slider-area .stock-text h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .stock-text h2 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-area .stock-text h2 {
    font-size: 52px;
  }
}

@media (max-width: 575px) {
  .slider-area .stock-text h2 {
    font-size: 27px;
    margin-bottom: 25px;
  }
}

.slider-area .stock-text h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 1px var(--primary-color);
  -webkit-text-fill-color: transparent;
}

.slider-area .stock-text h2:nth-child(2) {
  color: var(--primary-color);
  -webkit-animation: animate 4s ease-in-out infinite;
  animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      15% 44%,
      32% 50%,
      54% 60%,
      70% 61%,
      84% 69%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      16% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}

.slider-area .thumb-content-box {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 2;
  width: 35%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-area .thumb-content-box {
    width: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .thumb-content-box {
    width: 45%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-area .thumb-content-box {
    width: 58%;
  }
}

@media (max-width: 575px) {
  .slider-area .thumb-content-box {
    width: 86%;
  }
}

.slider-area .thumb-content-box .thumb-content {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 50px 143px 45px 80px;
  width: 100%;
  line-height: 1;
  background: var(--primary-color-rgb);
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider-area .thumb-content-box .thumb-content {
    padding: 50px 69px 45px 61px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-area .thumb-content-box .thumb-content {
    padding: 28px 69px 31px 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .thumb-content-box .thumb-content {
    padding: 21px 56px 25px 29px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-area .thumb-content-box .thumb-content {
    padding: 11px 49px 14px 26px;
  }
}

@media (max-width: 575px) {
  .slider-area .thumb-content-box .thumb-content {
    padding: 10px 66px 12px 10px;
  }
}

.slider-area .thumb-content-box .thumb-content h3 {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .thumb-content-box .thumb-content h3 {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .slider-area .thumb-content-box .thumb-content h3 {
    font-size: 14px;
  }
}

.slider-area .thumb-content-box .thumb-content a {
  color: #000000;
  display: block;
  position: relative;
  right: -34px;
  top: 6px;
  font-size: 30px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-area .thumb-content-box .thumb-content a {
    top: 4px;
  }
}

@media (max-width: 575px) {
  .slider-area .thumb-content-box .thumb-content a {
    top: 7px;
  }
}

.header-transparent {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
}

.slider-area2 .hero-cap h2 {
  color: #fff;
  font-size: 80px;
  font-weight: 800;
  text-transform: capitalize;
  line-height: 1;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-area2 .hero-cap h2 {
    font-size: 50px;
  }
}

@media (max-width: 575px) {
  .slider-area2 .hero-cap h2 {
    font-size: 35px;
  }
}

.about-area .about-caption {
  padding-left: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .about-area .about-caption {
    padding-left: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-area .about-caption {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-area .about-caption {
    padding-top: 50px;
    padding-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-area .about-caption {
    padding-top: 50px;
    padding-left: 0px;
  }
}

@media (max-width: 575px) {
  .about-area .about-caption {
    padding-top: 40px;
    padding-left: 0px;
  }
}

.about-area .about-caption .pera-top {
  font-size: 16px;
  font-weight: 500;
  color: #212025;
}

.about-area .about-caption .pera-bottom {
  font-size: 16px;
  color: #212025;
  font-weight: 300;
}

.about-area .about-shape {
  position: absolute;
  right: 20px;
  bottom: 160px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-area .about-shape {
    right: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-area .about-shape {
    right: 63px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-area .about-shape {
    right: 82px;
    bottom: 61px;
  }
}

@media (max-width: 575px) {
  .about-area .about-shape {
    right: 27px;
    bottom: 27px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-area {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service-area {
    padding-bottom: 70px;
  }
}

@media (max-width: 575px) {
  .service-area {
    padding-bottom: 70px;
  }
}

.custom-border {
  box-shadow: 0.5rem 0.5rem var(--primary-color),
    -0.5rem -0.5rem var(--secondary-color);
  background: var(--background-secondary-color);
}

.image-about {
  box-shadow: 4rem 4rem var(--primary-color), -4rem -4rem var(--secondary-color);
  width: 80%;
}

.service-area .services-caption {
  box-shadow: 0.5rem 0.5rem var(--primary-color),
    -0.5rem -0.5rem var(--secondary-color);
  background: var(--background-secondary-color);
  padding: 84px 45px 56px;
  border-radius: 7px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-area .services-caption {
    padding: 84px 25px 56px;
  }
}

.service-area .services-caption .service-icon {
  display: inline-block;
  position: relative;
}

.service-area .services-caption .service-icon::before {
  /* background-image: url(img/gallery/dot.webp); */
  background-image: url(../../../public/img/gallery/dot_black.png);
  content: "";
  width: 80px;
  height: 135px;
  position: absolute;
  right: -29px;
  top: -35px;
  z-index: 0;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.service-area .services-caption .service-icon i {
  position: relative;
  color: #fff;
  font-size: 40px;
  height: 105px;
  width: 105px;
  display: block;
  line-height: 105px;
  text-align: center;
  background: var(--secondary-color);
  margin-bottom: 42px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.service-area .services-caption .service-icon img {
  position: relative;
  color: #fff;
  font-size: 40px;
  height: 105px;
  width: 105px;
  display: block;
  line-height: 105px;
  text-align: center;
  background: var(--secondary-color);
  margin-bottom: 42px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.service-area .services-caption .service-cap h4 a {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 27px;
  display: inline-block;
}

.service-area .services-caption .service-cap h4 a:hover {
  color: var(--primary-color);
}

.services-caption:hover .service-icon::before {
  right: -32px;
}

.services-caption:hover .service-icon i {
  background: var(--primary-color);
}

.best-pricing {
  background: #fffcf9;
}

.best-pricing .pricing-list ul li {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  color: #010101;
  margin-bottom: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .best-pricing .pricing-list ul li {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .best-pricing .pricing-list ul li {
    font-size: 13px;
  }
}

.best-pricing .pricing-list ul li span {
  color: var(--primary-color) !important;
  font-weight: 400;
  font-size: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .best-pricing .pricing-img {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .best-pricing .pricing-img {
    padding-top: 60px;
  }
}

@media (max-width: 575px) {
  .best-pricing .pricing-img {
    padding-top: 60px;
  }
}

.best-pricing .pricing-img .pricing-img1 {
  position: absolute;
  left: 0;
  top: 0;
  height: 909px;
  width: 36%;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .best-pricing .pricing-img .pricing-img1 {
    width: 35%;
  }
}

@media (min-width: 1401px) and (max-width: 1600px) {
  .best-pricing .pricing-img .pricing-img1 {
    width: 35%;
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .best-pricing .pricing-img .pricing-img1 {
    width: 33%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .best-pricing .pricing-img .pricing-img1 {
    width: 39%;
    height: 905px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .best-pricing .pricing-img .pricing-img1 {
    position: unset;
    width: 70%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .best-pricing .pricing-img .pricing-img1 {
    position: unset;
    width: 80%;
    height: 450px;
  }
}

@media (max-width: 575px) {
  .best-pricing .pricing-img .pricing-img1 {
    position: unset;
    width: 90%;
    height: 320px;
  }
}

.best-pricing .pricing-img .pricing-img2 {
  position: absolute;
  bottom: 10%;
  left: 17%;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .best-pricing .pricing-img .pricing-img2 {
    left: 8%;
    width: 33%;
  }
}

@media (min-width: 1401px) and (max-width: 1600px) {
  .best-pricing .pricing-img .pricing-img2 {
    left: 9%;
    width: 33%;
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .best-pricing .pricing-img .pricing-img2 {
    left: 13%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .best-pricing .pricing-img .pricing-img2 {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .best-pricing .pricing-img .pricing-img2 {
    bottom: 9%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .best-pricing .pricing-img .pricing-img2 {
    display: none;
  }
}

@media (max-width: 575px) {
  .best-pricing .pricing-img .pricing-img2 {
    display: none;
  }
}

.box {
  position: relative;
}

.box img {
  width: 100%;
}

.box.snake {
  position: relative;
  overflow: hidden;
}

.overlay {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}

.box:hover .gallery-img {
  transform: scale(1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-area {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team-area {
    padding-bottom: 50px;
  }
}

@media (max-width: 575px) {
  .team-area {
    padding-bottom: 50px;
  }
}

.team-area .single-team {
  position: relative;
}

.team-area .single-team .team-img {
  overflow: hidden;
}

.team-area .single-team .team-img img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 400px;
  transform: scale(1);
  transition: all 0.6s ease-out 0s;
}

@media only screen and (min-width: 1199px) and (max-width: 1280px) {
  .team-area .single-team .team-img img {
    height: 300px;
  }
}

.team-area .single-team .team-caption {
  background-image: url(../../../public/img/gallery/team_bg.webp);
  width: 350px;
  height: 155px;
  background-repeat: no-repeat;
  padding-top: 57px;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 1;
  position: absolute;
  bottom: -71px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  background-size: contain;
}

@media (max-width: 1280px) {
  .team-area .single-team .team-caption {
    width: 250px;
    padding-top: 32px;
    bottom: -92px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-area .single-team .team-caption {
    width: 256px;
    padding-top: 32px;
    bottom: -92px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-area .single-team .team-caption {
    width: 260px;
    padding-top: 32px;
    bottom: -92px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team-area .single-team .team-caption {
    width: 220px;
    padding-top: 25px;
    bottom: -92px;
  }
}

@media (max-width: 575px) {
  .team-area .single-team .team-caption {
    width: 235px;
    padding-top: 25px;
    bottom: -92px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-area .single-team .team-caption::before {
    width: 256px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-area .single-team .team-caption::before {
    width: 260px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team-area .single-team .team-caption::before {
    width: 220px;
  }
}

@media (max-width: 575px) {
  .team-area .single-team .team-caption::before {
    width: 235px;
  }
}

.team-area .single-team .team-caption span {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
}

.team-area .single-team .team-caption h3 a {
  color: #ffff;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
  text-transform: uppercase;
}

.team-area .single-team:hover .team-caption::before {
  opacity: 1;
  visibility: visible;
}

.team-area .dot-style .slick-dots {
  text-align: center;
  bottom: -72px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-area .dot-style .slick-dots {
    bottom: -36px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team-area .dot-style .slick-dots {
    bottom: 0px;
  }
}

@media (max-width: 575px) {
  .team-area .dot-style .slick-dots {
    bottom: -10px;
  }
}

.team-area .dot-style .slick-dots li {
  display: inline-block;
}

.team-area .dot-style .slick-dots li button {
  text-indent: -999999px;
  padding: 0px;
  height: 5px;
  width: 20px;
  margin: 0px 4px;
  border: 0;
  background: #bfbfbf;
  transition: 0.3s;
  cursor: pointer;
}

.team-area .dot-style .slick-dots li.slick-active button {
  background: var(--primary-color);
  width: 35px;
}

.single-team:hover .team-img img {
  transform: scale(1.05);
}

.footer-bg {
  /* background-image: url(../../../public/img/gallery/footer_bg.webp); */
  background-color: var(--secondary-color);
  background-size: cover;
  background-position: center;
  position: relative;
}

/* .footer-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(var(--secondary-color), var(--secondary-color));
  opacity: 0.9;
  opacity: 0.9;
} */

.footer-area .footer-top {
  border-bottom: 1px solid #233148;
}

.footer-area .footer-top .single-footer-caption .footer-logo {
  margin-bottom: 30px;
}

.footer-area .footer-top .single-footer-caption .footer-pera .info1 {
  line-height: 1.8;
  margin-bottom: 35px;
  /* color: #7c7c7c; */
  color: var(--text-secondary-color)!important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-area .footer-top .single-footer-caption .footer-pera .info1 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area .footer-top .single-footer-caption .footer-pera .info1 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-area .footer-top .single-footer-caption .footer-pera .info1 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-area .footer-top .single-footer-caption .footer-pera .info1 {
    padding-right: 0px;
  }
}

.footer-area .footer-top .single-footer-caption .footer-number h4 {
  color: var(--primary-color);
  font-size: 30px;
  font-weight: 500;
  text-transform: capitalize;
}

.footer-area .footer-top .single-footer-caption .footer-number h4 span {
  color: #fff;
}

.footer-area .footer-top .single-footer-caption .footer-number p {
  color: #bdbdbd;
}

.footer-area .footer-top .single-footer-caption .footer-tittle h4 {
  color: var(--text-secondary-color)!important;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  margin-bottom: 38px;
  font-weight: 500;
  text-transform: uppercase;
}

.footer-area .footer-top .single-footer-caption .footer-tittle ul li {
  color: #7c7c7c;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.footer-area .footer-top .single-footer-caption .footer-tittle ul li a {
  color: var(--text-secondary-color)!important;
  font-weight: 300;
  font-size: 15px;
}

.footer-area .footer-top .single-footer-caption .footer-tittle ul li a:hover {
  color: var(--text-tertiary-color)!important;
  padding-left: 5px;
}

.footer-area .footer-top .single-footer-caption .footer-form {
  margin-bottom: 30px;
}

.footer-area .footer-top .single-footer-caption .footer-form form {
  position: relative;
}

.footer-area .footer-top .single-footer-caption .footer-form form input {
  width: 100%;
  height: 43px;
  padding: 10px 20px;
  border: 0;
  background: #101720;
  color: #fff;
}

.footer-area
  .footer-top
  .single-footer-caption
  .footer-form
  form
  input::placeholder {
  color: #9fa6bd;
  font-weight: 300;
  font-size: 14px;
}

.footer-area
  .footer-top
  .single-footer-caption
  .footer-form
  form
  .form-icon
  button {
  position: absolute;
  top: 0px;
  right: 0;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 14px 22px;
  background: #0a0f17;
  line-height: 1;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 300;
}

.footer-area .footer-top .single-footer-caption .info.error {
  color: #ff0000;
}

.footer-area .footer-top .single-footer-caption .footer-cap {
  margin-bottom: 30px;
}

.footer-area .footer-top .single-footer-caption .footer-cap span {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 10px;
  display: block;
}

.footer-area .footer-top .single-footer-caption .footer-cap p {
  color: #7c7c7c;
  font-size: 16px;
}

.footer-area .footer-bottom {
  padding-bottom: 50px;
  padding-top: 30px;
}

.footer-area .footer-bottom .footer-copy-right p {
  color: var(--text-secondary-color)!important;
  font-weight: 300;
  font-size: 16px;
  line-height: 2;
  margin-bottom: 12px;
}

.footer-area .footer-bottom .footer-copy-right p i {
  color: var(--primary-color);
}

.footer-area .footer-bottom .footer-copy-right p a {
  color: var(--primary-color);
}

.footer-area .footer-bottom .footer-copy-right p a:hover {
  color: #fff;
}

.footer-area .footer-social span {
  color: #fff;
  position: relative;
  margin-right: 57px;
}

.footer-area .footer-social span::before {
  position: absolute;
  content: "";
  width: 43px;
  height: 2px;
  background: #3b3647;
  top: 50%;
  transform: translateY(-50%);
  right: -56px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area .footer-social {
    float: left;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-area .footer-social {
    float: left;
    padding-top: 10px;
  }
}

@media (max-width: 575px) {
  .footer-area .footer-social {
    float: left;
    /* padding-top: 10px; */
  }
}

.footer-area .footer-social a {
  color: #7c7c7c;
  font-size: 14px;
  padding-left: 25px;
}

.footer-area .footer-social a i {
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  transform: rotateY(0deg);
}

.footer-area .footer-social a:hover {
  color: #fff;
}

.footer-area .footer-social a:hover i {
  transform: rotateY(180deg);
}

.form-contact label {
  font-size: 14px;
}

.form-contact .form-group {
  margin-bottom: 30px;
}

.form-contact .form-control {
  border: 1px solid #e5e6e9;
  border-radius: 0px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent;
}

.form-contact .form-control:focus {
  outline: 0;
  box-shadow: none;
}

.form-contact .form-control::placeholder {
  font-weight: 300;
  color: #999999;
}

.form-contact textarea {
  border-radius: 0px;
  height: 100% !important;
}

@media (max-width: 767px) {
  #test-form button.mfp-close {
    display: block !important;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.bg-gold {
  background-color: var(--primary-color) !important;
}

/* for firefox*/
:root {
  scrollbar-color: var(--secondary-color) var(--primary-color) !important;
  scrollbar-width: thin !important;
}

/*for  Chrome, Edge, and Safari */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--primary-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f8f8f8;
}

.scroller {
  overflow-y: scroll;
  scrollbar-color: var(--primary-color) var(--secondary-color);
}

.price-color {
  color: var(--primary-color);
}

.text-important-secondary {
  color: var(--text-secondary-color)!important;
}

.transperant-bg {
  background-color: transparent !important;
}
