@import url(../colors/scheme-01.css);

/* Customize the pagination color */
.c .swiper-pagination-bullet {
  background-color: var(--primary-color) !important; /* Change the color as desired */
}
/* C style */
.c-item {
  position: relative;
}

.c-item_title {
  z-index: 1;
  width: 100%;
  font-weight: bold;
  font-size: 32px;
  bottom: -5%;
  letter-spacing: .15em;
  position: absolute;
  text-align: center;
  color: var(--primary-color);
  font-family: var(--title-font);
}

.c-item_wm {
  width: 100%;
  font-size: 150px;
  letter-spacing: .15em;
  position: absolute;
  text-align: center;
  bottom: -25%;
  color: var(--tertiary-color);
  font-family: var(--title-font);
  color: transparent;
  -webkit-text-stroke: 1px var(--primary-color);
}

.c-item_wrap img {
  width: 450px;
  height: 450px;
  margin: 0 auto;
}

/* Arrow style */
.d-arrow-left,
.d-arrow-right {
  display: none;
  cursor: pointer;
  position: absolute;
  margin-top: -10px;
  z-index: 100;
  width: 30px;
  height: 60px;
  background-color: rgba(255, 255, 255, 1.0);
}
.d-arrow-left i,
.d-arrow-right i {
  position: absolute;
  z-index: 1000;
  top: 20px;
  color: var(--tertiary-color);
  font-size: 20px;
  font-weight: bold;
}

.d-arrow-left {
  left: 0px;
  border-top-right-radius: 110px;
  border-bottom-right-radius: 110px;
}
.d-arrow-right {
  right: 0px;
  border-top-left-radius: 110px;
  border-bottom-left-radius: 110px;
}



.d-arrow-left i {
  left: 8px;
}


.spacer-double {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}





.logo-carousel.owl-carousel .owl-item img {
  width: auto;
}




@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1300px;
  }

  .c-item_wrap img {
    width: 400px;
    height: 400px;
  }

}


@media only screen and (max-width: 1090px) {
  #quick_search {
    width: 200px;
  }

  #quick_search {
    position: absolute;
    top: 30px;
  }

  .c-item_wrap img {
    width: 350px;
    height: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .c-item_title {
    font-size: 24px;
  }
}