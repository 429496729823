.d-sch-table {
  position: relative;
  padding: 40px;
  border: solid 1px var(--primary-color);
}

.d-sch-table .d-content {
  font-family: var(--title-font);
  font-size: 32px;
  line-height: 42px;
  letter-spacing: .2em;
  margin-top: 10px;
}

.d-sch-table .d-deco {
  position: absolute;
  width: 100%;
  height: 100%;
  border: solid 1px var(--primary-color);
  top: 10px;
  left: 10px;
}

.d-sch-table .d-col {
  border-bottom: solid 1px rgba(var(--primary-color-rgb), .5);
  padding: 20px 0;
  margin-bottom: 2px;
}

.d-sch-table .d-col:last-child {
  border-bottom: none;
}

.de-separator:after {
  background: none repeat scroll 0% 0% var(--primary-color);
  content: "";
  height: 10px;
  right: 0%;
  margin: -5px auto auto -5px;
  position: absolute;
  transform: rotate(-45deg);
  width: 10px;
}

.de-separator:before {
  background: none repeat scroll 0% 0% var(--primary-color);
  content: "";
  height: 10px;
  left: 0%;
  margin: -5px auto auto -5px;
  position: absolute;
  transform: rotate(-45deg);
  width: 10px;
}

.de-separator {
  position: relative;
  border-top: 1px solid var(--primary-color);
  width: 250px;
  clear: both;
  margin: 0 auto;
  margin-bottom: 40px;
}




.de-country .d-title {
  padding: 20px;
  padding-bottom: 15px;
  cursor: pointer;
}

.de-country .d-title:after {
  font-family: FontAwesome;
  content: "\f078";
  margin-top: 3px;
  float: right;
}

.rtl .de-country .d-title:after {
  float: left;
}

.de-country.expand .d-title:after {
  content: "\f077";
}

.de-country .d-title h4 {
  display: inline-block;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 18px;
}