.service-header {
  text-align: left;
  margin-left: 8%;
  margin-bottom: 2rem;
}

.back-btn{
  font-size: 24px;
}

.category-border {
  border: 4px solid var(--primary-color);
  border-radius: 7px;
}

.service-back-btn {
  text-align: left !important;
}

.service-bg {
  background-color: var(--primary-color);
  transition: .2s all ease;
}

.category-title {
  margin-left: 7rem;
}











@media (min-width: 679px) and (max-width: 1279px) {
  .category-title {
    margin-left: 2rem;
  }

}

@media (max-width: 678px) {
  .category-title {
    margin-left: 1rem;
  }
  .back-btn{
    font-size: 18px;
  }

}