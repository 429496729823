#wrapper {
  position: relative;
  overflow: hidden;
}
#subheader {
  text-align: center;
  overflow: hidden;
  padding: 50px 0 120px 0;
  position: relative;
}

.slider__date {
  position: relative;
}
.slider__date input[type="radio"] {
  display: none;
}

.slider__date label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: transparent;
  border: 2px solid var(--primary-color);
}

.slider__date input[type="radio"]:checked + label {
  background-color: var(--primary-color);
  opacity: 1;
}

.calender__wrapper {
  top: 100%;
  left: 0;
  color: black;
  z-index: 10;
  background-color: #fafafa;
  border: 1px solid #fafafa;
}

.calendar__button {
  border: none;
  background: none;
  color: var(--primary-color);
  text-transform: uppercase;
}

.calendar__button span {
  margin-left: 8px;
  font-size: 20px;
}

.calendar__button:hover {
  border-bottom: 2px solid var(--secondary-color);
}

.time__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.time__container .time__wrapp {
  position: relative;
  border: 1px solid var(--primary-color);
  text-align: center;
}

.time__container .time__wrapp label {
  width: 100%;
  height: 100%;
  padding: 10px;
  text-align: start;
  cursor: pointer;
  transition: 0.4s ease-in-out 0s;
  font-size: 17px;
  font-weight: 700;
  background: transparent;
}

.time__container .time__wrapp label:after,
.time__container .time__wrapp label:before {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.time__container .time__wrapp input[type="radio"] {
  display: none;
}

.time__container .time__wrapp label:after {
  height: 20px;
  width: 20px;
  border: 2px solid var(--primary-color);
  right: 5px;
  top: calc(50% - 10px);
}

.time__container .time__wrapp label:before {
  background: #fff;
  height: 18px;
  width: 18px;
  right: 5px;
  top: 50%;
  top: calc(50% - 9px);
  transform: scale(5);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out 0s;
}

.time__container .time__wrapp input[type="radio"]:checked + label {
  background: var(--primary-color);
}

.time__container .time__wrapp input[type="radio"]:checked ~ label::before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

/* .arrow-btn{
  border: 1px solid var(--primary-color);
  width: 50%;
  padding: 5px;
  color: var(--secondary-color);
  height: 50%;
  border-radius: 100%;
} */
.swiper-button-prev {
  border: 1px solid var(--primary-color) !important;
  width: 35px !important;
  padding: 5px !important;
  color: var(--secondary-color) !important;
  height: 35px !important;
  border-radius: 100% !important;
}
.swiper-button-next {
  border: 1px solid var(--primary-color) !important;
  width: 35px !important;
  padding: 5px !important;
  color: var(--secondary-color) !important;
  height: 35px !important;
  border-radius: 100% !important;
}

.data-and-btn {
  margin-right: 50px;
}

@media (max-width: 678px) {
  .time__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .data-and-btn {
    margin-right: 40px;
  }
  .swiper-button-prev {
    width: 30px !important;
    height: 30px !important;
  }
  .swiper-button-next {
    width: 30px !important;
    height: 30px !important;
  }
}
