:root {

  /* 1st color */
/* ------------ approved --------- */

  --primary-color: #D19F68;
  --primary-color-rgb: rgba(209, 159, 104, 0.7);
  --secondary-color: #1D2434;
  --secondary-color-rgb: rgba(29, 36, 52, 0.7);
  --tertiary-color: #ffffff;
  --tertiary-color-rgb: rgba(255, 255, 255, 0.7);

  --background-primary-color: #fff;
  --background-secondary-color: #f8f8f8;
  --text-primary-color: #1D2434;
  --text-secondary-color: #fff;
  --text-tertiary-color: #D19F68;

/* 2nd colors */
/* ------optional -------- */

  /* --primary-color: #92A4B1;
  --primary-color-rgb: rgba(145, 163, 176, 0.7);
  --secondary-color: #061829;
  --secondary-color-rgb: rgba(5, 24, 41, 0.7);
  --tertiary-color: #ffffff;
  --tertiary-color-rgb: rgba(255, 255, 255, 0.7); */

  /* 3rd colors */

  /* -------rejected-------- */

  /* --primary-color: #936F62;
  --primary-color-rgb: rgba(148, 111, 97, 0.7);
  --secondary-color: #17212E;
  --secondary-color-rgb: rgba(23, 33, 46, 0.7);
  --tertiary-color: #ffffff;
  --tertiary-color-rgb: rgba(255, 255, 255, 0.7); */

  /* 4rd colors */

  /* --primary-color: #AD8786;
  --primary-color-rgb: rgba(172, 135, 134, 0.7);
  --secondary-color: #1E0508;
  --secondary-color-rgb: rgba(31, 5, 8, 0.7);
  --tertiary-color: #ffffff;
  --tertiary-color-rgb: rgba(255, 255, 255, 0.7); */

  /* 5rd colors */

  /* --primary-color: #AD8786;
  --primary-color-rgb: rgba(172, 135, 134, 0.7);
  --secondary-color: #061829;
  --secondary-color-rgb: rgba(5, 24, 41, 0.7);
  --tertiary-color: #ffffff;
  --tertiary-color-rgb: rgba(255, 255, 255, 0.7); */


}